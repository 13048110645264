<template>
<v-container>
  <!-- BOC:[breadcrumbs] -->
  <ABreadcrumbV1 :items="breadcrumbs"></ABreadcrumbV1>
  <!-- EOC -->
  <!-- BOC:[form] -->
  <BreadForm
    :role="role"
    :model="model"
    :url="`${this.$api.servers.event}/api/v1/en/moderator/event/${$route.params.parentId}/sponsor/edit`"
    action="edit"
    :self="me"
    :callbackSuccess="callbackSuccess"
  ></BreadForm>
  <!-- EOC -->
</v-container>
</template>

<script>
  //BOC:[model]
  import modelParent from '@/models/items/event'
  import model from '@/models/items/eventSponsor'
  //EOC
  import BreadForm from '@/components/Bread/BreadForm'
  import { mapState } from 'vuex'
  export default {
    components:{
      BreadForm,
    },
    computed: mapState({
      school: state => state.school.data,
    }),
    props:[
      'parent',
      'self',
    ],
    data: () => ({
      //BOC:[role]
      role:'Moderator',
      //EOC
      //BOC:[model]
      modelParent: modelParent,
      model: model,
      //EOC
      //BOC:[breadcrumbs]
      breadcrumbs: [],
      //EOC
      //BOC:[parent]
      myParent:{
        id:null,
      },
      //EOC
      //BOC:[self]
      me:{
        id:null,
      },
      //EOC
    }),
    created() {
      //BOC:[guard]
      if(!this.model[this.role].edit) {
        this.$router.replace(this.$_getRouteRead(this.role,this.model.key,this.$route.params.id,this.self))
        return
      }
      //EOC
      //BOC:[parent]
      if(this.parent) this.myParent = this.$_.cloneDeep(this.parent)
      //EOC
      //BOC:[self]
      if(this.self) {
        this.me = this.$_.cloneDeep(this.self)
      }
      else {
        this.$router.replace(this.$_getRouteRead(this.role,this.model.key,this.$route.params.id))
      }
      //EOC
      //BOC:[breadcrumbs]
      this.breadcrumbs.push({
        text:this.$t("model.name.dashboard"),
        to:{name:'Page'+this.role+'Dashboard'},
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:this.$t("model.name." + this.modelParent.name.plural.toLowerCase()),
        to:this.$_getRouteBrowse(this.role,this.modelParent.key),
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:(this.myParent && this.myParent.name) ? this.myParent.name : `${this.$t("model.name." + this.modelParent.name.singular.toLowerCase())} ${this.$route.params.parentId}`,
        to:this.$_getRouteRead(this.role,this.modelParent.key,this.$route.params.parentId,this.myParent),
        exact:true,
      })
      //
      let path = this.$_.cloneDeep(this.breadcrumbs[this.breadcrumbs.length-1].to)
      path.query = {tab:'Student'}
      this.breadcrumbs.push({
        text:this.$t("model.name." + this.model.name.plural.toLowerCase()),
        to:path,
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:(this.me.name) ? this.me.name : `${this.$t("model.name." + this.model.name.singular.toLowerCase())} ${this.$route.params.id}`,
        to:this.$_getRouteChildRead(this.role,this.model.key,this.$route.params.id,this.self,this.modelParent.key,this.$route.params.parentId,this.myParent),
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:this.$t("action.edit"),
        to:this.$_getRouteChildEdit(this.role,this.model.key,this.$route.params.id,this.self,this.modelParent.key,this.$route.params.parentId,this.myParent),
        exact:true,
      })
      //EOC
    },
    mounted() {
      //
    },
    methods: {
      callbackSuccess(resp) {
        let redirect = this.$_getRouteChildRead(this.role,this.model.key,resp.id,resp,this.modelParent.key,this.$route.params.parentId,this.myParent)
        this.$router.push(redirect)
      },
    }
  }
</script>